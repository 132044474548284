<template>

    <article class="productCardLarge" :class="cssClasses">


        <div class="productCardLarge__grid">

            <div class="productCardLarge__gridImage">

                <section class="productImageSection">


                    <div class="productCardImage" v-if="slideshow">

                        <product-images
                                :images="product.images"
                                :lazy-load="true"
                                size="thumbs"
                                :slideshow="true"
                                :show-arrows="true"
                                :show-thumbs="false"
                                :allow-zoom="false"
                        ></product-images>

                    </div> <!-- .productCardImage -->

                    <a :href="productUrl" class="productCardImage" v-else>

                        <product-images
                                :images="product.images"
                                :lazy-load="true"
                                size="thumbs"
                                :slideshow="false"
                                :show-arrows="false"
                                :show-thumbs="false"
                                :allow-zoom="false"
                        ></product-images>

                    </a>

                </section>
                
            </div> <!-- .productCardLarge__gridImage -->

            <div class="productCardLarge__gridContent">
                <div class="innerContent">
                
                    <header class="productHeader">
    
                        <stock-badge
                                :has-stock="inStock"
                                :price="fromPrice"
                                :show-price="false"
                                :currency-symbol="'£'"
                                :small="false"
                                :icon="true"
                                :bubble="true"
                                :css-class="['productPage', 'productLargeStock']"
                        ></stock-badge>
                        
                        <a :href="product.url">
                            <h2
                                class="productTitle"
                                v-text="product.name"
                                v-if="product.name"
                            ></h2>
                        </a>
                        
                    </header>
    
                    <div
                            class="itemDescription wysiwyg"
                            v-if="product.custom.description__product_card"
                            v-html="product.custom.description__product_card"
                    >
                    </div> <!-- .itemDescription -->
    
                    <div class="productLink">
                        
                        <a :href="product.url" title="View product" target="_blank" class="button buttonOutlined">
                            
                            <span class="buttonLabel">Full product details</span>
                            
                            <i class="svgIcon"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M10 5.48559L5.54444 9.99121L3.86667 9.99121L7.72222 6.09233L4.64936e-08 6.09233L6.08303e-08 4.89009L7.73333 4.89009L3.87778 0.991211L5.55556 0.991211L10 5.48559Z" fill="currentColor"></path></svg></i>
                            
                        </a>
                    </div> <!-- .productLink -->

                </div> <!-- .innerContent -->

            </div> <!-- .productCardLarge__gridContent -->

            <div class="productCardLarge__gridOptions">

                <add-to-basket
                        :ajax-add-to-basket="$store.state.minibasket.productCardsQuickAdd"
                        :basket-button-data="product.basket_button_data"
                        :browse-page="true"
                        :product="product"
                        :show-description="false"
                ></add-to-basket>
                
            </div> <!-- .productCardLarge__gridOptions -->
            
        </div> <!-- .productCardLarge__grid -->
        
    

    </article>

</template>

<script>

import _ from 'lodash';
import travelQuantityAdjust from './partials/travel_quantity_adjust';
import addToBasket from './partials/add_to_basket';


export default {
    name: "product-card-large",
    components: {
        addToBasket,
        travelQuantityAdjust
    },
    props: {
       
        classes: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
 
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        fromPrice: {
            type: [Number, Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        lazyLoad: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        slideshow: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        category: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        showDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    created() {

        if(this.optionCard && this.product) {
            if(_.isUndefined(this.product.quantity)) {
                this.product.quantity = 1;
            }
        }


    },
    mounted() {

        const vm = this;

        if(vm.useTrustpilot) {

            vm.interval = setInterval(() => {

                if (!_.isUndefined(window.Trustpilot)) {
                    clearInterval(vm.interval);
                    let elem = vm.$refs.trustbox;

                    if(elem) {
                        window.Trustpilot.loadFromElement(elem);
                        vm.trustpilotReady = true;
                    }
                }

            }, 1000);

        }

    },
    data() {
        return {
            maxDescriptionLength: 100,
            maxOptionDescriptionLength: 250,
            viewProductText: 'View product',
            fallbackImagePath: '/sites/default/images/no-image-items-thumb.png',
            quantityAdjustVisible: false,
            interval: null,
            
        }
    },
    computed: {

        productDescription() {

            if(!this.showDescription) return false;

            let text = '';

            
            text = this.product.description;
            

            if(text.length) {

                let maxLength = this.maxDescriptionLength;

                if(this.optionCard) {
                    maxLength = this.maxOptionDescriptionLength;
                }

                let append = '';

                if(text.length > maxLength) {
                    append = '...';
                }

                return text
                        .replace(/(<([^>]+)>)/gi, "")
                        .substring(0,maxLength)
                        + append;

            }

            return false;
        },

        productImageSrc() {

            if(!this.productImage) {
                return this.fallbackImagePath;
            }

            return this.productImage;
        },

        cssClasses() {

            let classes = [...[], ...this.classes];


            return classes;
        }

    },

    methods: {

        triggerSelected() {

            let id = 0;

            if(!_.isUndefined(this.product.id)) {
                id = this.product.id;
            }

            if(!_.isUndefined(this.optionSize.id)) {
                id = this.optionSize.id;
            }


            this.$emit('option-selected', this.product.id, id);
        },

        triggerDeselected() {

            let id = 0;

            if(!_.isUndefined(this.product.id)) {
                id = this.product.id;
            }

            if(!_.isUndefined(this.optionSize.id)) {
                id = this.optionSize.id;
            }

            this.$emit('option-deselected', this.product.id, id);
        },

        updateQuantitySelected(qty) {


            if(!_.isUndefined(qty)) {

                qty = parseInt(qty);

                if(!isNaN(qty)) {

                    if(this.optionSize) {
                        this.optionSize.quantity = (qty > 0) ? qty : this.product.minimum_quantity;
                    }

                    this.product.quantity = qty;
                }
            }

            this.quantityAdjustVisible = false;


            this.triggerSelected();


        },

        getConfigOrFalse(key) {

            if(!_.isUndefined(window.globalConfig)) {

                if(!_.isUndefined(window.globalConfig[key])) {
                    return window.globalConfig[key];
                }

            }

            return false;

        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .productCardLarge {
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        height: 100%;
        position: relative;
        text-align: left;
        background: #F7F0EE;

        
        &__grid {
            display: flex;
            
            @media all and (max-width: 1100px) {
                display: grid;
                grid-template-columns: 200px auto;
                grid-template-rows: auto auto;
            }
            
            @media all and (max-width: 700px) {
                grid-template-columns: 130px auto;
            }
            
            //@media all and (max-width: 700px) {
            //    display: flex;
            //    flex-wrap: wrap;
            //}
            
        }

        &__gridImage {
            flex: 0 0 210px;

            @media all and (max-width: 1100px) {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: span 2;
            }

            //@media all and (max-width: 700px) {
            //    flex: 0 0 100%;
            //}
        }

        &__gridContent {
            flex: 1 1 100%;
            padding: 35px 27px 35px 35px;

            @media all and (max-width: 1100px) {
                padding: 35px 27px 16px 35px;
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 2;
            }

            //@media all and (max-width: 700px) {
            //    flex: 0 0 100%;
            //}
            //
            //@media all and (max-width: 500px) {
            //    padding-top: 12px;
            //}
            
            .innerContent {
                display: flex;
                flex-flow: column nowrap;
                justify-content:space-between;
            }
        }

        &__gridOptions {
            flex: 1 1 100%;
            padding: 35px 35px 35px 27px;

            @media @flyout {
                padding: 16px 35px 35px 27px;
                grid-row-start: 2;
                grid-row-end: 2;
                grid-column-start: 2;
            }

            //@media all and (max-width: 700px) {
            //    flex: 0 0 100%;
            //}
        }
        
        .productImageSection {
            height: 100%;
        }
        
        .productCardImage {
            height: 100%;
        }
        
        .productImage {
            position: relative;
            top: auto;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            .mainImage {
                height: 100%;
                
                //@media all and (max-width: 700px) {
                //    max-width: 200px;
                //    max-height: 200px;
                //    margin: 32px auto;
                //}
            }
            
        }
        
        
        .productHeader {
            display: flex;
        }
        
        .productTitle {
            flex: 1 1 100%;
            margin: 0;
            padding-left: 20px;
            .rems(18);
            font-weight: 400;
            line-height: 25px;
            
            @media all and (max-width: 700px) {
                padding-left: unset;
            }
        }
        
        .itemDescription {
            padding-top: 20px;
            
            @media all and (max-width: 700px) {
                display: none;
            }
            
            &.wysiwyg {
                
                .rems(12);
                line-height: 14px;
                
                ul {
                    
                    margin-left: 30px !important;
                    padding-bottom: 16px !important;
                    
                    li {
                        padding: 0;
                        list-style: none !important;
                        position: relative;
                        margin: 0 0 21px;
                        
                        &:last-child {
                            margin: 0;
                        }
                        
                        &:before {
                            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><rect width="20" height="20" rx="10" fill="%238497B5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5C9.44772 5 9 5.44772 9 6V9L6 9C5.44772 9 5 9.44771 5 10C5 10.5523 5.44772 11 6 11H9V14C9 14.5523 9.44772 15 10 15C10.5523 15 11 14.5523 11 14V11H14C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9L11 9V6C11 5.44772 10.5523 5 10 5Z" fill="white"/></svg>');
                            position: absolute;
                            left: -20px;
                            top: -2px;
                            width: 20px;
                            height: 20px;
                            display: block;
                        }
                    }
                }
            }
        }
        
        .productLink {
            padding: 20px 0 0;
            
            .button {
                margin: 0;
            }
            
            @media all and (max-width: 700px) {
                display: none;
            }
        }
        
        .stockWrap {
            flex: 0 0 auto;
            
            .stockBubble {
                gap: 0;
            }
            
            .bubbleText {
                position: relative;
                top: -1px;
            }
        }

        .basketActions {

            height: 100%;
            
            .basketForm {
                
                .productSizes {
                    padding-bottom: 32px;
                }
                
                .multiSize {
                    @media all and (max-width: 500px) {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    
                    .productSize {
                        @media all and (max-width: 500px) {
                            flex: 0 0 100%;
                        }
                    }
                }
                
                .basketButtonRow {

                    @media all and (max-width: 500px) {
                        flex-wrap: wrap;
                    }
                    
                    .rowButton {
                        @media all and (max-width: 500px) {
                            flex: 0 0 100%;
                            padding: 12px 0 0 0 !important;
                        }
                    }
                    
                    
                }
                
                .addToBagButton {
                    position: relative;
                    padding: 12.5px 40px 14.5px 15px;
                    text-align: left;
                    
                    .svgIcon {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: -1px 0 0 0;
                    }
                }
                
            }
            
        }

    }


</style>
